// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-contact-page-tsx": () => import("/opt/build/repo/src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-imprint-page-tsx": () => import("/opt/build/repo/src/templates/imprint-page.tsx" /* webpackChunkName: "component---src-templates-imprint-page-tsx" */),
  "component---src-templates-thankyou-page-tsx": () => import("/opt/build/repo/src/templates/thankyou-page.tsx" /* webpackChunkName: "component---src-templates-thankyou-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("/opt/build/repo/src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("/opt/build/repo/src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-de-blog-index-tsx": () => import("/opt/build/repo/src/pages/de/blog/index.tsx" /* webpackChunkName: "component---src-pages-de-blog-index-tsx" */),
  "component---src-pages-en-blog-index-tsx": () => import("/opt/build/repo/src/pages/en/blog/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

