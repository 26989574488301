import * as React from "react";
import Transition from "./src/components/Transition";

export const onServiceWorkerUpdateReady= () => {
  // var isEn = true;
  // if (typeof window !== "undefined") {
  //   if (window.location.pathname.startsWith("/de")) {
  //     isEn = false;
  //   }
  // }

  // // const container = document.createElement("div");
  // // container.style.cssText =
  // //   "font-family: Tahoma; font-size: 16px; line-height: 40px; color: whitesmoke; background-color: #404040; text-align: center; position: fixed; bottom: 0px; left: 50%; margin-left: -150px; width: 300px;";
  // // const content = document.createElement("span");
  // // content.innerHTML = isEn
  // //   ? "<strong>A new version is available<strong>&nbsp;&nbsp;<u>Refresh</u>"
  // //   : "<strong>Aktualisierung verfügbar</strong>&nbsp;&nbsp;<u>Neuladen</u>";
  // // container.onclick = () => {
  // //   document.location.reload();
  // // };
  // // container.appendChild(content);
  // // document.body.appendChild(container);

  // if (
  //   window.confirm(
  //     isEn
  //       ? "A new version is available. Reload to get the newest version?"
  //       : "Eine neue Version ist verfügbar. Neuladen um die neuste Version zu laden?"
  //   )
  // ) {
  //   window.location.reload(true);
  // }

  window.location.reload(true);
} 

// export const wrapPageElement = ({ element, props }) => {
//   return <Transition {...props}>{element}</Transition>;
// };
